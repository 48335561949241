.bg-second {
  background-color: $cSecond;
}
.bg-light {
  background-color: $cPrimaryLight;
}
.bg-cover {
  background-size: cover !important;
  background-repeat: no-repeat !important;
}
.bg-home-carousel {
  // background-image: url("/assets/images/Groupe-281.jpg");
  @extend .bg-cover;
}
.bg-banner {
  // background:background: rgb(0,0,0);
  background: linear-gradient(
      155deg,
      transparent,
      rgb(9 9 121 / 54%) 17%,
      #011270
    ),
    url("/assets/images/home-banner.jpeg");
  background-size: cover;
}
.bg-1 {
  background: transparent linear-gradient(117deg, #b286b8 0%, #0608a0 100%) 0%
    0% no-repeat padding-box;
}
.bg-2 {
  background-color: rgb(124, 43, 43);
}
.bg-3 {
  background-image: linear-gradient(to right, $cPrimaryLight, $cSecond);
}
.bg-4 {
  background-image: url("/assets/images/Groupe-273.jpg");
}
.bg-5 {
  background-color: $cPrimaryLight;
}
.bg-6 {
  background-color: #d4504b;
}
.bg-7 {
  background-color: #a46f9d;
}
.bg-8 {
  background-color: #ef9c44;
}
.bg-9 {
  background-color: #4aa1b4;
}
.bg-10 {
  background-color: $cPrimaryLightPlus;
}
.bg-11 {
  background-color: #0d122d;
}
.bg-12 {
  background-color: #0a0e27;
}
.bg-13 {
  background-image: url("/assets/images/bg-auth.jpg");
}
.bg-14 {
  background-image: url("/assets/images/404.svg");
}

.main-sub-menu {
  background-color: $cDarkLightPlus;
  padding: 0;
  margin: 0;
  position: sticky;
  top: 20px;
  @media screen and (max-width: 992px) {
    top: 100px;
  }
  @media screen and (max-width: 768px) {
    top: 50px;
  }
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    li {
      padding: 0;
      a {
        padding: 12px 9px 12px 12px;
        display: inline-block;
        width: 100%;
        transition: 0.3s;
        color: $cPrimary;
        text-transform: uppercase;
        font-size: $p4S;
        &:hover {
          padding: 12px 5px 12px 16px;
          background-color: $cPrimary;
        }
      }
    }
  }

  .sub-menu-item-active {
    background-color: $cPrimary;
    color: $cSecond;
  }
}
.national-office {
  h4 {
    color: lighten($color: $cDark, $amount: 20);
    font-size: $p1S;
  }
  p {
    color: $cDarkLight;
  }
  ul {
    @extend %greenMenu;
  }

  .qoute-para {
    font-size: $h3S;
  }
  a {
    color: $cPrimary;
  }
}
.single-important-a {
  .single-important {
    background: white;
    padding: 6px 10px;
    border-radius: 6px;
    box-shadow: 4px 5px 10px #00000017;
    margin-bottom: 10px;
    transition: 0.3s;
    &:hover {
      background-color: #f7a82e;
    }
  }
  color: $cPrimaryDark;
  &:hover {
    color: $cSecond;
    text-decoration: none;
  }
}
.important-links-container {
  position: sticky;
  top: 20px;
}
.press-articles {
  .owl-nav {
    bottom: -60px;
    top: unset;
  }
}
.articles-image-container {
  height: 175px;
  padding: 20px 30px;
  margin: 5px;
  background-color: $cSecond;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 10px 15px #0000000d;
  img {
    width: 100%;
    height: 100%;
  }
}
.reference-icon {
  max-width: 300px;
}
.mediatech-container {
  padding: 19px;
  margin: 14px;
  border-radius: 30px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  min-height: 280px;
  h3 {
    color: $cPrimary;
  }
  p {
    color: $cDark;
  }
}

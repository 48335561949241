:root {
  /* headings */
  --h1-home: 47px;
  --h3-home: 24px;
  --h1-size: 40px;
  --h2-size: 30px;
  --h3-size: 20px;
  --h4-size: 15px;
  --h5-size: 60px;
  --h6-size: 16px;
  /* paragraphs */
  --para1-size: 18px;
  --para2-size: 16px;
  --para3-size: 14px;
  --para4-size: 13px;
  /* others */
  --header-height: 165.31px;
}
@media only screen and (max-width: 992px) {
  :root {
    /* headings */
    --h1-home: 30px;
    --h3-home: 18px;
    --h1-size: 30px;
    --h2-size: 20px;
    --h3-size: 17px;
    --h4-size: 15px;
    --h5-size: 60px;
    --h6-size: 16px;
    /* paragraphs */
    --para1-size: 15px;
    --para2-size: 14px;
    --para3-size: 13px;
    --para4-size: 12px;
    /* others */
    --header-height: 165.31px;
  }
  .first {
    order: 1;
  }
  .second {
    order: 2;
  }
}
/* headings */
$h1S: var(--h1-size);
$h2S: var(--h2-size);
$h3S: var(--h3-size);
$h4S: var(--h4-size);
$h6S: var(--h6-size);
$homeH1: var(--h1-home);
/* paragraphs */
$p1S: var(--para1-size);
$p2S: var(--para2-size);
$p3S: var(--para3-size);
$p4S: var(--para4-size);
/* colors */
$cPrimary: #224f9f;
$cPrimaryLight: #f5f6ff;
$cPrimaryLightPlus: #f1f5ff;
$cPrimaryDark: #1f2d7e;
$cSecond: #ffff;
$cSecondLight: rgba(255, 255, 255, 0.678);
$cDark: #393939;
$cDarkLight: #858585;
$cDarkLightPlus: #e4e4e4;
$cThird: var(--third-color);
$inputBgColor: #e0fbff;
$inputPlaceHolder: #13afa7;
$cFormation: #13afa7;
/* other variables */
$headerHeight: var(--header-height);

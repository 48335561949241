.home-banner {
  height: calc(100vh - 144.411px);
  @media screen and (max-width: 992px) {
    height: 100vh;
  }
  .carousel-inner {
    height: 100%;
    .carousel-item {
      height: 100%;
    }
  }
  .carousel-caption {
    right: unset;
    bottom: unset;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 500px;
    top: 50%;
  }
  .carousel-indicators {
    top: 100%;
    transform: translateY(-20px);
    align-items: center;
    li {
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }
  }

  ngb-carousel {
    &:focus {
      outline: none;
    }
    .picsum-img-wrapper {
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .costum-flex {
    justify-content: flex-end;
    @media screen and (max-width: 992px) {
      justify-content: center;
    }
  }
  h1 {
    color: $cSecond;
  }
  .max-width {
    max-width: 560px;
  }
}
.big-number {
  font-family: $mainFontsBold;
  font-size: $h1S;
  position: relative;
  margin-bottom: 0 !important;
  &::after {
    content: "";
    width: 32px;
    height: 3px;
    background: $cDark;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 28px;
    opacity: 0.7;
  }
}
.light-para {
  font-size: $p2S;
  color: $cDark;
  opacity: 0.7;
  margin: 0;
}
.p-para-light {
  font-size: $p1S;
  color: $cPrimaryLight;
}
.owl-nav {
  position: absolute;
  top: 0;
  right: 0;
  [class*="owl-"] {
    background-color: unset !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    padding: 10px 14px !important;
    &:hover {
      filter: brightness(0.5);
    }
  }
  .owl-prev {
    background-image: url("/assets/icons/arrow-backword-sharp-3.svg") !important;
  }
  .owl-next {
    background-image: url("/assets/icons/arrow-forward-sharp-2.svg") !important;
  }
}
.owl-carousel .owl-stage {
  padding-top: 40px;
}
.link-arrow {
  color: $cPrimary;
  img {
    display: inline !important;
    width: 30px !important;
  }
  &:hover {
    text-decoration: none;
  }
}
.erasmus-joint .over-content {
  opacity: 1;
}
.over-content {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  background-color: #003e8f5b;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: 0.3s;
  &:hover {
    opacity: 1;
  }
  .main {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .location {
    padding: 8px 6px;
    img {
      height: 17px;
      width: 17px;
      margin-right: 4px;
      transform: translateY(-4px);
    }
  }
  @media screen and (max-width: 992px) {
    opacity: 1;
  }
}
.frequent-questions {
  .card {
    border: unset;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
    .card-body {
      padding-top: 0;
    }
  }
  .card:not(:last-of-type) {
    border: none;
  }
  .card-header {
    background-color: unset;
    border-bottom: none;
  }
  button {
    width: 100%;
    text-align: left;
    .arrow-down {
      float: right;
      transform: translateY(3px);
    }
    &:hover {
      text-decoration: none;
    }
    &:focus {
      box-shadow: none;
    }
    .question {
      color: $cDark;
      text-decoration: none !important;
    }
    .number {
      margin-right: 10px;
      font-size: $h2S;
      color: $cPrimary;
      font-family: $mainFontsBold;
      display: inline-block;
      transform: translateY(4px);
    }
  }
}
.singleNews-image {
  height: 250px;
  object-fit: cover;
  object-position: center;
}
.profile-content-height {
  height: 325px;
}
.carousel-control-prev-icon {
  background-image: url("/assets/icons/arrow-gauche.svg");
}
.carousel-control-next-icon {
  background-image: url("/assets/icons/arrow-droit.svg");
}
.carousel-control-prev,
.carousel-control-next {
  opacity: 1;
}
.home-carousel-image {
  background-color: #00000066;
  background-blend-mode: darken;
}

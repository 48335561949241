.footer-input {
  border-radius: 20px;
  padding: 5px 15px;
  background-color: transparent;
  border: solid 1px rgba(255, 255, 255, 0.568);
  color: $cSecond;
  &:focus {
    outline: none;
  }
}
footer {
  .location {
    img {
      margin-right: 5px;
      margin-bottom: 10px;
    }
  }
  .social {
    a {
      img {
        margin-right: 10px;
        width: 23px;
        height: 23px;
        filter: contrast(7.5);
        &:hover {
          filter: brightness(0);
        }
      }
    }
  }
  .arrow-right {
    transform: rotateZ(-90deg) translateX(1px);
    width: 14px;
    margin-right: 2px;
  }
  p,
  input,
  li,
  a {
    opacity: 0.7;
    color: $cSecond;
    transition: 0.3s;
    &:hover {
      opacity: 1;
      text-decoration: none;
      color: $cSecond;
    }
  }
  h3 {
    color: $cSecond;
    opacity: 1 !important;
    text-transform: uppercase;
  }
  li {
    list-style: none;
    padding-bottom: 7px;
    padding-top: 7px;
    border-bottom: 1px solid white;
  }
  hr {
    border-top: 1px solid $cSecond;
    opacity: 0.5;
    max-width: 600px;
    margin: 24px auto;
  }
}

.p-button {
  overflow: hidden;
  font-family: $mainFontsBold;
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: uppercase;
  background-color: $cSecond;
  padding: 10px 64px 10px 10px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  position: relative;
  box-shadow: 5px 5px 10px #00000036;
  color: $cDark;

  display: inline-block;
  width: fit-content;
  .after-button {
    position: absolute;
    right: 9px;
    z-index: 33;
    top: 50%;
    transform: translateY(-50%);
    line-height: 0.3;
  }
  &:hover {
    text-decoration: none;
    background-image: linear-gradient($cSecond);
    box-shadow: 5px 5px 10px #00000036;
    &:after {
      width: 60px;
    }
  }
  &:after {
    content: "";
    transition: 0.3s;
    text-align: right;
    height: 155%;
    width: 51px;
    background-color: $cPrimary;
    position: absolute;
    right: -18px;
    top: 26%;
    transform-origin: top;
    transform: translateY(-23px) rotateZ(15deg) !important;
  }
}
@mixin flat-btn($color) {
  border: solid 1px $color;
  color: $color;
  background-color: transparent;
  padding: 5px 10px;
  border-radius: 10px;
}
.flat-btn-danger {
  @include flat-btn(red);
}
.flat-btn {
  @include flat-btn($cPrimary);
}

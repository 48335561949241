.auth-module {
  .input-group-text {
    background-color: unset;
    border-color: $cSecond;
    i {
      color: $cPrimaryLight;
    }
  }
  input,
  textarea {
    &:focus {
      color: $cSecond;
    }
    color: $cSecond;
  }

  .form-control {
    background: transparent;
    border-color: $cSecond;
    &:focus {
      background-color: transparent;
      box-shadow: none;
      border-color: $cSecond;
    }
  }

  .form-check {
    padding-left: 0;
  }
}
.auth-p-button {
  @include auth-p-button;
}
.costum-height {
  min-height: calc(100vh - 144.411px);
  @media screen and (max-width: 992px) {
    min-height: calc(100vh - 116.43px);
  }
}

.InputAddOn-item {
  cursor: pointer;
  display: inline-block;
  width: 40px;
}
p {
  font-size: $p1S;
}
a:hover {
  text-decoration: none;
}
h2 {
  font-size: $h2S;
}
.clean-a {
  cursor: pointer;

  &:hover {
    text-decoration: none;
    color: $cSecond;
  }
  &:focus {
    outline: none;
  }
}
.invalid {
  border: solid 1px red;
}
.valid {
  border: solid 1px black;
}
.text-danger {
  color: red;
}
.eye {
  width: 19px;
  transform: translateY(4px);
  margin-left: 5px;
  transform: 0.3s;
}
.blured {
  filter: blur(1px);
}

.container {
  max-width: 1140px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}
.home-container {
  border: solid 1px black;
}
.new-file {
  .mat-horizontal-stepper-header-container {
    display: none !important;
  }
}
/* started here for real  */
/* headings */
h1 {
  font-size: $h1S;
}
h3 {
  font-size: $h3S !important;
}
.top-nav {
  background-color: $cPrimaryLight;
}
.input-1 {
  @include input-1;
}
.t-primary {
  color: $cPrimary;
}
.c-second {
  color: $cSecond !important;
}
.mh-100vh {
  min-height: 100vh;
}

.p-button {
  @include p-button;
}
.obj-cover {
  object-fit: cover;
}
.line-under-secColor {
  @include lineUnder($cPrimary);
}
.b-dark {
  border: solid 2px $cDark;
}
.c-dark {
  color: $cDark;
}
.c-primary {
  color: $cPrimary !important;
}
.para-light {
  font-size: $p1S;
  color: $cDark;
  opacity: 0.8;
}
.s-button {
  @include s-button;
}
.medium-size {
  font-size: $p3S;
}
.small-size {
  font-size: $p4S !important;
}
.first {
  order: 1;
}
.second {
  order: 2;
}
.bold {
  font-family: $mainFontsBold;
}
.hover-pointer {
  cursor: pointer;
}
.goback-btn {
  color: $cPrimary;
  transition: 0.3s;
  img {
    width: 30px;
    margin-right: 6px;
    transform: translateY(-2px);
    transition: 0.3s;
  }
  &:hover {
    color: $cPrimaryDark;
    img {
      filter: brightness(0.5);
    }
  }
}
.small-h1 {
  font-size: $h3S !important;
}
.my-linear-btn {
  @include my-linear-btn;
}
.rounded-btn-primary {
  @include rounded-btn($cPrimary, $cPrimaryDark, $cSecond);
}
.rounded-btn-second {
  @include rounded-btn($cSecond, $cSecond, $cPrimary);
}
.spinner-holder {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 333;
  bottom: 0;
  background-color: rgba($cPrimary, 0.4);
  .spinner-grow {
    background-color: $cPrimary;
  }
}
.rounded-btn-second[class*="icon-left"] {
  padding: 10px 12px 10px 68px;
  &::after {
    left: 5px;
    transform: translateY(-50%) rotate(180deg);
  }
}
[disabled] {
  cursor: not-allowed;
}
.btn-comment {
  @include btn-comment;
}
button {
  &:focus {
    outline: none !important;
  }
}
.image-404 {
  transform-origin: top;
  animation: slideDown 0.2s;
}
@keyframes slideDown {
  0% {
    transform: scaleY(0);
  }

  100% {
    transform: scaleY(1);
  }
}
.search-input {
  max-width: 320px;
  .input-group-text {
    background-color: transparent;
    border-radius: 4.25rem;
    border-color: rgba($cPrimary, 0.4);
  }

  .form-control {
    border-radius: 4.25rem;
    padding: 1.375rem 0.75rem;
    background-color: transparent;
    border-color: rgba($cPrimary, 0.4);
    &:focus {
      outline: none !important;

      background-color: transparent;
      outline: none;
      border-color: rgba($cPrimary, 0.4);
      box-shadow: unset;
    }
  }
  .my-linear-btn {
    height: max-content;
  }
}
.input-group[class*="search-input"] {
  width: unset;
}
.br-0 {
  border-right: none !important;
}
.bl-0 {
  border-left: none !important;
}
button[class*="rounded-btn-primary"] {
  border: none;
  width: unset !important;
}
.my-accordion {
  .card {
    background-color: transparent;
  }
  .arrow-down {
    transform: translateY(15px) !important;
  }
}
.padding-for-menu {
  @media screen and (max-width: 992px) {
    padding-top: 96px !important;
  }
}
.page-not-found {
  position: relative;
  z-index: 33333;
  @media screen and (max-width: 992px) {
    height: 100vh !important;
  }
}
.special-container {
  background-color: #ecf1fb;
  padding: 20px;
  p {
    color: $cDark !important;
  }
  h3 {
    font-weight: bold;
  }
}
.high-z-index {
  z-index: 99;
}
.smart-container {
  padding: 15px;
  height: 100%;
  background-color: $cSecond;
  border-radius: 10px;
  transition: 0.3s;
  img {
    transition: 0.3s;
    width: 60px;
    height: 60px;
  }
  p {
    transition: 0.3s;
    color: $cDark;
    font-size: $p3S;
  }
  h3 {
    transition: 0.3s;
    color: $cPrimary;
    font-size: $p1S !important;
  }
  &:hover {
    background-color: $cPrimary;
    @include small-shadow;
    img {
      filter: brightness(13);
    }
    p {
      color: $cSecond;
    }
    h3 {
      color: $cSecond;
    }
  }
}
.simple-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  .image {
    height: 250px;
    object-fit: cover;
    width: 100%;
    object-position: center;
  }
  h2 {
    font-size: $h3S;
    margin-top: 10px;
    font-weight: bold;
  }
  .description {
    h1 {
      font-size: $h2S !important;
    }
  }
}
.simple-card-2 {
  .title {
    font-size: $h3S !important;
    margin: 10px 0;
  }
  .description {
    font-size: $p3S;
  }
  img {
    width: 77px;
    height: 77px;
  }
}
.link-with-icon-before {
  position: relative;
  padding-left: 30px;
  color: $cPrimary;
  display: inline-block;
  margin-bottom: 10px;
  &::before {
    content: "";
    width: 30px;
    height: 13px;
    background-image: url("/assets/icons/triple-arrows.svg");
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    left: 0;
    top: 5px;
  }
}
.link-with-icon-after {
  position: relative;
  padding-right: 44px !important;
  color: $cPrimary;
  display: inline-block;
  margin-bottom: 10px;
  background-color: $cSecond;
  transition: 0.3s;
  width: 100%;
  padding: 16px 20px;
  border-radius: 4px;
  box-shadow: 0px 7px 15px #00000017;
  text-transform: uppercase;
  &::before {
    content: "";
    width: 30px;
    height: 13px;
    background-image: url("/assets/icons/triple-arrows.svg");
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
  }
  &:hover {
    background-color: $cPrimary;
    color: $cSecond;
  }
}
.more-btn-reform {
  border: solid 1px $cPrimary;
  padding: 7px 15px;
  color: $cPrimary;
  transition: 0.3s;
  &:hover {
    text-decoration: none;
    background-color: $cPrimary;
    color: $cSecond;
  }
}
.break-word {
  word-break: break-word;
}
.is-liked {
  filter: sepia(19) contrast(0.5) contrast(0.5) contrast(0.5) !important;
}
.card-with-overlay {
  .p-button {
    transform: scale(0.6);
  }
}
.table-one {
  thead {
    background-color: $cPrimary;
    color: $cSecond;
  }
  th {
    font-weight: normal;
  }
}
.simpler-card {
  img {
    height: 250px;
    object-fit: cover;
    object-position: center;
    width: 100%;
  }
}
.colored-container {
  background-color: $cPrimary;
  padding: 1rem 2rem;
  border-radius: 6px;
  p {
    margin: 0;
    color: $cSecond !important;
  }
  a {
    color: #f7a82e !important;
  }
}
.single-article-image {
  max-height: 500px;
  object-fit: cover;
  object-position: center;
}
.is-active {
  @include shadow-2();
  border-radius: 20px;
  padding: 10px 68px 10px 12px;
  transition: 0.3s;
  color: $cSecond !important;
  background-image: linear-gradient(to right, $cPrimary, $cPrimary) !important;
  position: relative;
  display: inline-block;
  &::after {
    content: "";
    width: 30px;
    height: 30px;
    background-image: url(/assets/icons/Group-376.svg);
    background-size: contain;
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
  }
  &:hover {
    background-image: linear-gradient(to right, $cSecond, $cSecond) !important;
    color: $cPrimary !important;
    @include small-shadow();
    text-decoration: none;
  }
}
button[disabled] {
  opacity: 0.5;
}
.image-container-resource {
  width: 56px;
  height: 56px;
  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}
.refactoring-items {
  height: 100%;
  display: flex;
  flex-direction: column;
  p {
    flex-grow: 1;
  }
}
.small-text {
  font-size: 12px;
}
.resources-modal {
  .modal-dialog {
    max-width: 90%;
  }
}

.chart-title {
  color: $cPrimary;
  text-transform: capitalize;
  text-align: center;
  margin-bottom: 20px;
}
.chart-container {
  padding-top: 22px;
  padding-bottom: 22px;
}
figure[class*="image"] {
  padding: 20px;
}

.pagination {
  li::before {
    content: unset !important;
  }
}
.page-item {
  cursor: pointer;
  &.disabled {
    cursor: not-allowed;
  }
}
.c-light {
  color: $cSecond;
}
a.rounded-btn-primary {
  color: $cSecond !important;
  &:hover {
    color: $cPrimary !important;
  }
}

.simple-card .description {
  max-height: 200px;
  overflow: hidden;
}
.home-news-card .description {
  max-height: 200px;
  overflow: hidden;
  table {
    background-color: white !important;
  }
}

.card-html h1,
.card-html h2,
.card-html h3 {
  max-width: 300px;
  font-size: 25px !important;
  line-height: 26px !important;
}

.my-table {
  // background-color: $cPrimary;
  border-radius: 8px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.close-button {
  background-color: red;
  border: none;
  border-radius: 50%;
  padding: 2px 8px 3px 10px;
  color: white;
  opacity: 0.4;
  transition: 0.3s;
  &:hover {
    opacity: 1;
  }
}
.extra-modal .modal-dialog {
  max-width: 900px;
}
.cursor-pointer {
  cursor: pointer;
}

.analytics-outer {
  padding: 12px 30px;
}
.single-analytics-container {
  h2 {
    text-align: center;
  }
  border-radius: 12px;
  padding: 12px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

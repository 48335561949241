.establishment-statistics-container {
  padding: 20px 20px;
  background-image: linear-gradient(
    to right,
    $cPrimary,
    rgba($cPrimaryDark, 0.8)
  );
  box-shadow: 10px 25px 35px #00000059;
  border-radius: 20px;
}
.establishment-overview-container {
  background: $cSecond 0% 0% no-repeat padding-box;
  padding: 27px 27px;
  border-radius: 20px;
  @include small-shadow;
  .logo {
    width: 130px;
    height: 130px;
    border-radius: 50%;
  }
  .inner-container {
    border-radius: 20px;
    background: $cPrimaryLight 0% 0% no-repeat padding-box;
    padding: 19px;
    display: flex;
    img {
      margin-right: 9px;
    }
    h3 {
      margin: 0;
      color: rgba($cPrimaryDark, 0.5);
      font-size: $p2S !important;
    }
    .number {
      font-family: $mainFontsBold;
    }
  }
  p {
    margin-bottom: 9px;
    img {
      height: 20px;
      width: 25px;
      transform: translateY(-4px);
      margin-right: 7px;
    }
    a:hover {
      color: $cPrimary;
    }
  }
  .btn-holder {
    display: inline-block;
    padding: 9px;
  }
}
.my-linear-btn-white {
  @extend .my-linear-btn;
  background-image: linear-gradient(to right, transparent, transparent);
  color: $cPrimary;
  border: solid 1px $cPrimary;
  &:hover {
    background-image: linear-gradient(to right, $cPrimary, $cPrimary);
    color: $cSecond;
  }
}
.update-container {
  background: $cSecond 0% 0% no-repeat padding-box;
  border-radius: 20px;
  padding: 15px;
  @extend .mt-4;
  .row-container {
    @include row-container;
    h4 {
      font-family: $mainFontsBold;
      font-size: $h4S;
      @extend .mb-3;
    }
    .form-group {
      opacity: 0.8;
      input,
      select {
        border-radius: 0;
      }
    }
  }
}

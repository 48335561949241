@font-face {
  font-family: "helvetica";
  src: url("/assets/fonts/Helvetica.ttf");
}
@font-face {
  font-family: "helvetica bold";
  src: url("/assets/fonts/Helvetica-Bold.ttf");
}
@font-face {
  font-family: "helvetica bold oblic";
  src: url("/assets/fonts/Helvetica-BoldOblique.ttf");
}
@font-face {
  font-family: "helvetica oblic";
  src: url("/assets/fonts/Helvetica-Oblique.ttf");
}
@font-face {
  font-family: "helvetica rounded bold";
  src: url("/assets/fonts/helvetica-rounded-bold-5871d05ead8de.otf");
}
$mainFonts: "helvetica";
$mainFontsBold: "helvetica bold";
$mainFontsBoldOblic: "helvetica bold oblic";
$mainFontsOblic: "helvetica oblic";
$mainFontsRoundedBold: "helvetica rounded bold";

body,
div {
  font-family: $mainFonts;
}

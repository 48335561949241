.espace-alumni {
  p {
    color: $cDarkLight;
  }
  .big-title {
    color: $cPrimary;
    font-size: $h1S;
  }
  ul {
    @extend %greenMenu;
  }
  .join-us-container {
    background: transparent
      radial-gradient(closest-side at 50% 50%, #f5f5f545 0%, #bcbbbb 100%) 0% 0%
      no-repeat padding-box;
    border-radius: 8px;
    ul li::before {
      filter: brightness(0.5) brightness(0.5);
    }
    .text-container {
      padding: 20px 0px;
    }
    .image-container {
      img {
        max-height: 428px;
        position: absolute;
        bottom: 0;
        max-width: 100%;
        @media screen and (max-width: 992px) {
          position: static;
          margin-top: -90px;
        }
      }
      @media screen and (max-width: 600px) {
        text-align: center;
      }
    }
    .social-container {
      font-size: 12px;
      img {
        margin-right: 4px;
      }
      a {
        color: $cDark;
      }
    }
  }
}
.colout-continaer {
  padding-left: 255px;
  h2 {
    font-size: 41px;
    color: #f7a82e;
  }
  h3 {
    font-size: $h1S;
    color: $cPrimary;
  }
  @media screen and (max-width: 600px) {
    padding-left: 0px;
    padding-bottom: 100px;
  }
}
.alumni-en-action-carousel {
  background-color: $cSecond;
  .owl-nav {
    bottom: -18px;
    right: 18px;
    top: unset;
    bottom: 0;
  }
  .content {
    padding: 11px 45px 42px 34px !important;
  }
}
.covid-carousel {
  p {
    margin-bottom: 2px;
  }
  h3 {
    margin-top: 12px;
  }
}
.retreat-carousel {
  .carousel-translated-content {
    background: $cSecond;
    padding: 2rem;
    border-radius: 1rem;
    transform: translateX(15%);
    @media screen and (max-width: 768px) {
      transform: translateX(0);
    }
  }
}

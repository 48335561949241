app-download-new-file {
  height: 100%;
}
.new-icm {
  height: 100%;
  display: flex;
  flex-direction: column;
  .spinner-border {
    border: 0.25em solid transparent;
    border-right-color: $cPrimary;
    width: 5rem;
    height: 5rem;
  }
  button {
    border: none;
  }
  .single-page {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .grow-1 {
      flex-grow: 1;
    }
  }
  .custom-file-input {
    color: transparent;
    opacity: 1;
    outline: none;
    height: fit-content;
  }
  .custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
  }
  .custom-file-input::before {
    content: "upload excel file";
    color: black;
    display: inline-block;

    border-radius: 12px;
    padding: 21px 19px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-weight: 700;
    font-size: 10pt;
    width: 100%;
    text-align: center;
    @include small-shadow;
    border: solid 0.5px $cDark;
    transition: 0.3s;
    &:hover {
      opacity: 0.4;
    }
  }

  .custom-file-input:active {
    outline: 0;
  }
  .custom-file-input:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
  }
  .file-container {
    border-radius: 20px;
    border: 1px solid $cPrimary;
    padding: 20px;
    .csv-container {
      @include image-container(70px);
      img {
        width: 40px;
      }
    }
  }
  .close-btn {
    @include image-container(40px);
    background-color: $cPrimary;
    transition: 0.3s;
    img {
      width: 14px;
    }
    &:hover {
      opacity: 0.5;
    }
  }
  .row-container {
    @include row-container;
  }
  .page-1 {
    .max-width-500 {
      max-width: 500px;
    }
  }
  .loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
  }
}

.download-modal {
  .modal-dialog {
    max-width: 750px !important;
  }
  .modal-dialog-centered {
    align-items: unset;
  }
  .modal {
  }
  top: 50%;
  transform: translateY(-50%);
  height: unset;
}

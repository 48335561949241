.top-nav {
  a {
    color: $cPrimary;
    transition: 0.3s;
    &:hover {
      text-decoration: none;
      color: $cPrimaryDark;
    }
  }
}
.navbar {
  button {
    color: $cPrimary;
    outline: none;
  }
  .nav-link {
    color: $cSecond;
  }
  a {
    text-transform: uppercase;
    font-size: $p4S !important;
  }
}
.menu-nav {
  .dropdown {
    &:hover {
      .dropdown-menu {
        display: block;
      }
    }
  }
  transition: 0.3s;
  background-color: $cPrimary;
  .dropDown-menu {
    background-color: $cPrimaryDark;
    border-radius: 0;
    margin-top: -1px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .dropdown-item {
    transition: 0.3s;
    border-bottom: solid 1px rgba($cSecond, 0.3);
    padding: 10px 20px 10px 20px;
    &:last-child {
      border: 0;
    }
    &:hover {
      padding: 10px 15px 10px 25px;
      background-color: $cPrimary;
      color: $cSecond;
      text-transform: uppercase;
    }
    &:focus {
      background-color: unset;
    }
  }
  @media screen and (max-width: 992px) {
    background-color: transparent;
    position: fixed;
    top: 0;
    z-index: 3333;
  }
}
.image-side-text {
  @extend .t-primary;
  font-size: 10px;
}
.navbar-nav {
  width: 100%;
  justify-content: space-between;
  .nav-item {
    cursor: pointer;
    transition: 0.3s;
    padding: 10px 1px;
    &:hover {
      background-color: $cPrimaryDark;
    }
  }
  @media screen and (max-width: ≥992px) {
    .nav-link {
      padding: 15px;
    }
  }
}
.mobile-menu {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  background-color: $cSecond;
  z-index: 22222;
  animation: fadeIn 0.3s ease-in forwards;
  transition: 0.3s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.mobile-nav-container {
  padding: 16px;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  ul {
    flex-grow: 1;
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .dropdown-menu {
      position: unset !important;
      transform: translate(0, 0) !important;
      background-color: transparent;
      border: none;
      text-align: center;
      a {
        color: $cPrimary !important;
      }
    }
    .nav-link {
      text-align: center;
      color: $cDark;
    }
  }
  button {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: $cPrimary;
    padding: 8px;
    transition: 0.3s;
    border: none;
    display: flex;
    float: right;
    &:hover {
      opacity: 0.5;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.scrolled {
  @media screen and (max-width: 992px) {
    background-color: $cSecond;
    @include shadow-down;
    @include shadow-down;
  }
}
.profile-dropdown {
  button {
    border: none;
    &:hover {
      opacity: 0.5;
    }
    &:active,
    &:focus {
      background-color: transparent;
    }
  }
  .dropdown-menu {
    transform: translate(50%, 52px) !important;
    border-radius: 0;
  }
}

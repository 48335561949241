$translatePercentage: 10%;

%translated-content {
  position: absolute;
  max-width: 300px;
  background: $cSecond;
  padding: 21px 15px 14px 15px;
  top: $translatePercentage;
  box-shadow: 10px 17px 25px #0000000d;
  border-radius: 6px;
  p {
    padding-top: 20px;
  }
}

.image-translated-content-container {
  position: relative;
  margin-bottom: $translatePercentage;
  .fixer {
    width: 10%;
    display: inline-block;
  }
  img {
    width: 90%;
    height: 400px;
    object-fit: cover;
  }
  .translated-content-right {
    @extend %translated-content;
    right: 0;
  }
  .translated-content-left {
    @extend %translated-content;
    left: 0;
  }
}

.cliped-text-container {
  background-color: #f7a82e;
  padding: 20px 15px 23px 15px;
  border-radius: 9px;
  h2 {
    color: $cSecond;
    margin: 10px 10px;
  }
  p[class*="caption"] {
    color: $cSecond;
    padding: 10px;
    font-size: 16px;
    width: fit-content;
  }
  .details-container {
    background-color: $cSecond;
    padding: 6px 14px;
    P {
      margin: 0;
      color: $cPrimary;
      font-size: 14px;
    }
  }
  .site-button {
    color: $cSecond;
    border: solid 1px $cSecond;
    padding: 5px 15px;
    transition: 0.3s;

    &:hover {
      opacity: 0.4;
    }
  }
}

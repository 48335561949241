.comment-container {
  background: $cSecond 0% 0% no-repeat padding-box;
  box-shadow: 15px 20px 20px #0000000a;
  border-radius: 20px;
  padding: 20px;
  @extend .p-2, .p-md-3, .p-lg-4, .mb-3, .mb-md-5;
  p {
    color: $cDark;
    opacity: 0.8;
  }
  textarea {
    background-color: transparent;
    border: none;
    width: 100%;
    &:focus {
      outline: none;
    }
  }
  .person {
    margin-bottom: 15px;
    img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }

    .details {
      padding-top: 6px;
      h3 {
        margin-bottom: 0;
      }
      span {
        text-transform: uppercase;
        opacity: 0.5;
      }
      margin-left: 10px;
    }
  }
  .reactions {
    img {
      margin-right: 8px;
    }
    span {
    }
    .light {
      opacity: 0.5;
    }
    a {
      color: $cDark;
      &:hover {
        color: $cPrimary;
      }
    }
  }
}
.comment-container-reply {
  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  textarea {
    width: 100%;
    padding: 15px;
    background-color: $cPrimaryLightPlus;
    border: none;
    border-radius: 20px;
    box-shadow: 15px 20px 20px #0000000a;
    &:focus {
      outline: none;
    }
  }
  .publish-button {
    @include btn-comment;
    border-color: $cSecond;
    background-color: $cSecond;
    color: $cPrimary;
    padding: 8px 27px 8px 27px;
    &:hover {
      border-color: $cPrimary;

      background-color: $cPrimary;
      color: $cSecond;
    }
  }
}

.costum-container {
  background-image: linear-gradient(to right, $cSecond, $cSecond);
  @include shadow-2;
  border-radius: 15px;
  padding: 18px;
  max-width: 300px;
  margin: 20px auto;
  display: flex;
  align-items: center;
  position: relative;
  transition: 0.3s;
  overflow: hidden;
  img {
    height: 30px;
    width: 30px;
  }
  h3 {
    display: inline-block;
    color: $cDark;
    font-size: $h2S;
    transition: 0.3s;
    margin: 0 0 0 12px;
    z-index: 11;
  }
  .image-container {
    @include image-container(56px);
  }
  &:hover {
    @include small-shadow;
    background-image: linear-gradient(to right, #551ad0, #381e61);
    h3 {
      text-decoration: none !important;
      color: $cSecond;
    }
    .image-container {
      background-color: $cSecond;
      @include small-shadow;
    }
    &::before {
      animation: grow-shrink 1s;
    }
  }
  &::before {
    content: "";
    @include small-shadow;
    width: 0%;
    height: 100%;
    border-radius: 15px;
    position: absolute;
    top: 0px;
    left: 0px;
    opacity: 0;
    transition: 0.3s;
  }
}
.costum-container[class*="disabled"] {
  opacity: 0.4;
}
.costum-container-btn {
  background-color: transparent;
  border: none;
  margin: none;
  width: 100%;
}
@keyframes grow-shrink {
  0% {
    width: 0%;
    height: 100%;
    opacity: 1;
  }

  100% {
    width: 100%;
    height: 100%;
    opacity: 1;
  }
}

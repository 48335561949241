@mixin shadow-down {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}
@mixin small-shadow {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
@mixin shadow-2 {
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
    rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}
%greenMenu {
  list-style: none;
  padding: 0;
  li {
    position: relative;
    padding-left: 24px;
    color: $cDarkLight;
    margin-bottom: 6px;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      width: 13px;
      height: 13px;
      top: 5px;
      background-image: url("/assets/icons/menu-right.svg");
      background-size: contain;
    }
    a {
      color: $cPrimary;
      &:hover {
        text-decoration: none;
        color: $cPrimaryDark;
      }
    }
  }
}
@mixin basic-input {
  outline: none !important;
  &:hover {
    outline: none !important;
  }
  &:focus {
    outline: none !important;
  }
  &:active {
    outline: none !important;
  }
}
@mixin input-1 {
  @include basic-input();
  border-radius: 15px;
  border: solid 2px $cPrimary;
  background-color: $cPrimaryLight;
}
@mixin p-button {
}
@mixin lineUnder($lineUnderColor) {
  position: relative;
  display: inline;
  color: $cDark;
  font-size: var(--h2-size);
  &::after {
    content: "";
    height: 5px;
    width: 90px;
    background-color: $lineUnderColor;
    display: block;
    margin-top: 8px;
    position: absolute;
    left: 0;
    border-radius: 10px;
  }
}
@mixin s-button {
  color: $cSecond;
  padding: 8px 20px;
  border-radius: 20px;
  border: solid 1.3px $cSecond;
  text-transform: uppercase;
  transition: 0.3s;
  &:hover {
    color: $cSecond;
    text-decoration: none;
    opacity: 0.5;
  }
}
@mixin auth-p-button {
  width: 100%;
  background-color: $cSecond;
  border-radius: 6px;
  color: $cPrimary;
  border: none;
  padding: 8px;
  transition: 0.3s;
  @include shadow-2();
  @include basic-input;
  &:hover {
    @include small-shadow();
    color: $cSecond;
    background-color: $cPrimary;
  }
}
@mixin my-linear-btn {
  background-image: linear-gradient($cPrimary, $cPrimaryDark);
  padding: 11px 20px;
  border-radius: 10px;
  color: $cSecond;
  display: inline-block;
  font-size: $p4S;
  transition: 0.3s;
  @include shadow-2();

  img {
    margin-right: 3px;
    transform: translateY(-2px);
    transition: 0.3s;
  }
  &:hover {
    text-decoration: none;
    color: $cPrimary;
    background-image: linear-gradient($cSecond, $cSecondLight);
    @include small-shadow;
    img {
      filter: brightness(0.5);
    }
  }
}
@mixin rounded-btn($color, $bgColor, $cthree) {
  @include shadow-2();
  border-radius: 20px;
  padding: 10px 68px 10px 12px;
  transition: 0.3s;
  color: $cthree;
  background-image: linear-gradient(to right, $color, $bgColor);
  position: relative;
  display: inline-block;
  &::after {
    content: "";
    width: 30px;
    height: 30px;
    background-image: url(/assets/icons/Group-376.svg);
    background-size: contain;
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
  }
  &:hover {
    background-image: linear-gradient(to right, $cthree, $cthree);
    color: $bgColor;
    @include small-shadow();
    text-decoration: none;
  }
}
@mixin image-container($height) {
  padding: 7px;
  transition: 0.3s;
  width: $height;
  height: $height;
  border-radius: 50%;
  background-color: $cPrimaryLight;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 11;
}
@mixin btn-comment {
  padding: 4px 27px 4px 27px;
  border: solid 0.6px $cPrimary;
  background-color: $cSecond;
  color: $cPrimary;
  transition: 0.3s;
  border-radius: 10px;
  @include shadow-2();
  &:hover {
    @include small-shadow();
    background-color: $cPrimary;
    color: $cSecond;
  }
}
@mixin row-container {
  border-radius: 20px;
  padding: 20px;
  box-shadow: 15px 20px 20px #00000014;
  margin-bottom: 20px;
  select {
    width: 100%;
    padding: 6px 5px;
    &:focus {
      outline: none;
    }
  }
}
